import { MetaFunction } from '@remix-run/cloudflare';

export const routeMeta: MetaFunction = () => {
  return [
    { title: 'Sign in - UptimeInsight' },
    {
      name: 'description',
      content:
        "Welcome back! Sign in to access your service monitoring dashboard and stay on top of your IT infrastructure's health."
    }
  ];
};
