import type { LoaderFunction, ActionFunction, MetaFunction } from '@remix-run/cloudflare';
import { useActionData, useLoaderData } from '@remix-run/react';

import { Text } from '~/components/Catalyst/text';
import { Link } from '~/components/Catalyst/link';

import { ActionResponse, LoaderResponse } from './types';
import { SignType } from '~/components/Auth/types';
import SignForm from '~/components/Auth/SignForm';
import { routeAction } from './action';
import { routeLoader } from './loader';
import { routeMeta } from './meta';

export const loader: LoaderFunction = routeLoader;
export const action: ActionFunction = routeAction;
export const meta: MetaFunction = routeMeta;

export default function Component() {
  const { csrf, siteKey } = useLoaderData<LoaderResponse>();
  const actionData = useActionData<ActionResponse>();

  return (
    <>
      <div className="isolate flex min-h-dvh items-center justify-center bg-zinc-50 p-6 lg:p-8 dark:bg-zinc-950">
        <div className="w-full max-w-md rounded-lg bg-white shadow-md ring-1 ring-zinc-950/5 dark:bg-zinc-900 dark:ring-white/10">
          <SignForm
            errors={actionData?.errors as any}
            defaults={actionData?.defaults}
            otpEmail={actionData?.otpEmail}
            type={SignType.SignIn}
            siteKey={siteKey}
            csrf={csrf}
          />
          <div className="m-1.5 rounded-lg bg-zinc-50 py-4 text-center text-sm/5 ring-1 ring-zinc-950/5 dark:bg-zinc-950 dark:ring-white/10">
            <Text className="inline-block">Not a member?</Text>{' '}
            <Link
              href="/sign-up"
              className="font-medium transition-opacity duration-300 hover:opacity-60"
            >
              Start a 7 day free trial
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
